<template>
    <el-card class="management-report-card-box">
        <div>
            <div class="flex flex-row justify-between items-center mb-2">
                <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                <div class="flex items-center">
                    <el-tag v-if="!changeInEquityData.isRowsOriginal" type="danger">Det har skett ändringar i bokföringen</el-tag>
                    <RefreshButton
                        :card-title="title"
                        :annual-report-id="annualReportId"
                        :refresh-data="refreshData"
                        loading-key="loading-changeInEquity"
                        :success-message="`Hämtat ny data till förändringar i eget kapital`"
                        :disable-button="showEditButton"
                    />
                    <InfoButton :content="infoContent" />
                    <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                </div>
            </div>
            <el-form v-loading="$waiting.is('loading-changeInEquity')">
                <div style="margin-left: 310px;" class="flex items-end">
                    <div v-for="(title, index) in columnData" :key="index">
                        <div class="flex justify-end align-end mb-2 mr-5" v-if="!disableTotal(title)">
                            <el-button plain size="mini" @click="deleteColumn(index)" :disabled="inputDisabled" v-if="columnData.length > 2">
                                <i class="fa-solid fa-minus" />
                            </el-button>
                            <el-button plain size="mini" @click="addColumn(index)" :disabled="inputDisabled">
                                <i class="fa-solid fa-plus" />
                            </el-button>
                        </div>
                        <el-input
                            class="break-normal custom-textarea"
                            v-model="columnData[index]"
                            type="textarea"
                            :rows="2"
                            style="width: 130px; margin-right: 20px;"
                            :disabled="inputDisabled || disableTotal(title)"
                        />
                    </div>
                </div>
                <div style="width: fit-content;">
                    <el-table :data="rowData" size="mini" :show-header="false">
                        <el-table-column prop="description" width="300" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.description" :disabled="inputDisabled || disableLast(scope.row)" />
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(value, index) in rowData[0].amounts" :key="index" prop="amounts" width="150" editable>
                            <template slot-scope="scope">
                                <div v-if="index !== scope.row.amounts.length - 1 && scope.row.description !== 'Belopp vid årets utgång'">
                                    <el-input
                                        v-model.number="scope.row.amounts[index]"
                                        :disabled="inputDisabled || (index === scope.row.amounts.length - 1 ? true : disableLast(scope.row))"
                                        type="number"
                                        :value="scope.row.amounts[index] || 0"
                                        @input="handleEmptyInputValues($event, index, scope.row)"
                                    />
                                </div>
                                <div v-else-if="index === scope.row.amounts.length - 1 && scope.row.description !== 'Belopp vid årets utgång'">
                                    <el-input disabled :value="getSumRow(scope.row.amounts)" type="number" />
                                </div>
                                <div v-show="scope.row.description === 'Belopp vid årets utgång'">
                                    <el-input disabled :value="getSumCol(index, scope.row.amounts, scope.$index)" type="number" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="120">
                            <template slot-scope="{ $index, store, row }">
                                <template v-if="!disableLast(row)">
                                    <div class="flex flex-row">
                                        <el-button plain size="mini" @click="deleteRow(row)" :disabled="inputDisabled" v-if="store.states.data.length !== 2">
                                            <i class="fa-solid fa-minus" />
                                        </el-button>
                                        <el-button plain size="mini" @click="addRow(row)" :disabled="inputDisabled">
                                            <i class="fa-solid fa-plus" />
                                        </el-button>
                                    </div>
                                </template>
                                <template v-else>
                                    <el-tooltip
                                        v-if="!isTotalValid()"
                                        placement="top"
                                        effect="dark"
                                        :content="'S:a eget kapital från bokföring: ' + rowData[rowData.length - 1].totalValue"
                                        :width="150"
                                    >
                                        <i class="fa-solid fa-circle-exclamation" style="color: orange; padding: 6px 0 ;" />
                                    </el-tooltip>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-if="toggleValue" style="min-width: 800px;">
                    <div v-show="showComment">
                        <h2 class="text-base font-semibold mb-2 mt-4">Kommentar:</h2>
                        <el-input type="textarea" v-model="initialInputText" :disabled="inputDisabled" class="break-normal custom-textarea" />
                    </div>
                </div>
            </el-form>
            <CardEditButtons
                :toggle-value="toggleValue"
                :show-edit-button="showEditButton"
                :toggle-edit="toggleEdit"
                :close-modal="closeModal"
                :create="save"
                :show-comment-button="showCommentButton"
                :show-comment="showComment"
                @toggle-comment="handleToggleComment"
            />
        </div>
    </el-card>
</template>
<script>
import { Switch } from "element-ui";
import Api from "../annualReport.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        changeInEquityData: {
            type: Object,
            default: () => {},
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        rowData: {
            type: Array,
            default: () => [],
        },
        columnData: {
            type: Array,
            default: () => [],
        },
        inputText: {
            type: String,
            default: "",
        },
        showComment: {
            type: Boolean,
        },
        annualReportId: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            updateChangeInEquityData: [],
            selectedYear: null,
            financialYearId: null,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            showCommentButton: true,
            infoContent: `ÅRL 6 kap 2 § - Aktiebolag och ekonomiska föreningar ska i förvaltningsberättelsen eller i egen räkning specificera förändringar i eget kapital jämfört med föregående års balansräkning. <br /><br /> K2 (BFNAR 2016:10) kapitel 5 avsnittet Förändringar i eget kapital`,
            initialInputText: "",
            initialYearlyOverviewData: [],
            sumIsUpdated: false,
            newRows: [],
            newColumnData: [],
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
        RefreshButton: () => import("./components/RefreshData.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
        showComment(newVal) {
            this.$emit("update:showChangesInEquityComment", newVal);
        },
    },
    methods: {
        isDataChanged() {
            const inputTextChanged = this.initialInputText !== this.inputText;
            const rowDataChanged = this.rowData.some((row, rowIndex) => {
                return row.amounts.some((value, colIndex) => {
                    return this.initialYearlyOverviewData[rowIndex].amounts[colIndex] !== value;
                });
            });
            const columnDataChanged = this.columnData.some((column, index) => {
                return this.initialYearlyOverviewData[0].columnData[index] !== column;
            });
            return inputTextChanged || rowDataChanged || columnDataChanged;
        },
        isTotalValid() {
            const inputTotalValue = this.rowData[this.rowData.length - 1].amounts[this.rowData[this.rowData.length - 1].amounts.length - 1];
            const bookingTotalValue = this.rowData[this.rowData.length - 1].totalValue;
            return inputTotalValue === bookingTotalValue;
        },
        handleEmptyInputValues(value, index, row) {
            if (value === "" || value === null) {
                row.amounts[index] = null;
            } else {
                row.amounts[index] = Number(value);
            }
        },
        getSumRow(array) {
            if (Array.isArray(array)) {
                const sumValue = array.slice(0, -1).reduce((sum, num) => sum + num, 0);
                array[array.length - 1] = sumValue;
                return sumValue;
            }
            return 0;
        },
        getSumCol(rowIndex, row, colIndex) {
            let columnSumValue = 0;
            this.rowData.slice(0, -1).forEach(row => {
                const value = row.amounts[rowIndex];
                if (value !== null && !isNaN(value)) {
                    columnSumValue += value;
                }
            });
            const lastIndexInRowData = this.rowData.length - 1;
            if (row !== undefined && lastIndexInRowData === colIndex) {
                row[rowIndex] = columnSumValue;
            }
            return columnSumValue;
        },
        handleToggleComment(newShowComment) {
            this.showComment = newShowComment;
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 6,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async refreshData() {
            const refreshChangeInEquity = await Api.getManagementReportBooking(this.annualReportId, "changeInEquity");
            this.newRows = refreshChangeInEquity.rows;
            this.newColumnData = refreshChangeInEquity.columnTitles;
            this.$emit("update:row", this.newRows);
            this.$emit("update:column", this.newColumnData);
            this.changeInEquityData.isRowsOriginal = true;
        },
        async save() {
            this.updateChangeInEquityData = this.changeInEquityData;
            console.log("updateChangeInEquityData", this.updateChangeInEquityData);
            this.$emit("update-change-in-equity-data", this.updateChangeInEquityData);
            this.$emit("create");
            this.$notify.success({ title: 'Sparat "Förändringar i eget kapital"' });
            this.toggleEdit();
        },
        closeModal() {
            const hasChanges = this.isDataChanged();
            const load = "loading-changeInEquity";
            if (hasChanges) this.initialInputText = this.inputText;
            this.$emit("close", { load: load, inputTextNewValue: hasChanges });
            this.toggleEdit();
        },
        addRow(row) {
            const newObj = {
                description: "",
                amounts: [null, null, null, null, null, null, 0],
            };
            const index = this.rowData.indexOf(row);
            this.rowData.splice(index + 1, 0, newObj);
        },
        deleteRow(row) {
            if (this.rowData.length > 0) {
                const index = this.rowData.indexOf(row);
                if (index > -1) {
                    this.rowData.splice(index, 1);
                }
            }
        },
        addColumn(index) {
            this.columnData.splice(index + 1, 0, "");
            this.rowData.forEach(row => {
                row.amounts.splice(index + 1, 0, null);
            });
        },
        deleteColumn(index) {
            this.columnData.splice(index, 1);
            this.rowData.forEach(row => {
                row.amounts.splice(index, 1);
            });
        },
        disableLast(row) {
            return row.description === "Belopp vid årets utgång";
        },
        disableTotal(title) {
            return title === "Totalt";
        },
    },

    mounted() {
        this.initialYearlyOverviewData = JSON.parse(JSON.stringify(this.rowData));
        this.initialYearlyOverviewData[0].columnData = JSON.parse(JSON.stringify(this.columnData));
        this.initialInputText = JSON.parse(JSON.stringify(this.inputText));
    },
};
</script>
